<template>
  <div>
    <v-text-field
      v-model="value"
      v-if="field.fieldType === 'TEXT'"
      :label="field.name || 'Nombre del campo o pregunta'"
      :hint="field.description"
      :rules="computedRequired ? requiredRules : []"
      persistent-hint
      filled
      rounded
      :disabled="computedDisabled"
    >
    <template v-slot:append v-if="computedEnableAppendIcon">
      <v-menu offset-y open-on-hover origin="center center"
      transition="scale-transition">
        <template v-slot:activator="{on, attrs}">
          <v-icon
            v-on="on"
            v-bind="attrs"
            @click="showFielInDataSheet"
            :color="computedColorAndIcon.color"
          >{{ computedColorAndIcon.icon }}</v-icon>
        </template>
        <v-sheet color="#ffffff92" class="pa-2" style="font-size: 14px; font-weight: 500; width: 16rem">
            Usa este botón para mostrar u ocultar esta información en tu ficha técnica cuando se escanee el QR de tu credencial virtual.
        </v-sheet>
      </v-menu>
    </template>
  </v-text-field>
    <v-select
      v-model="value"
      v-if="field.fieldType === 'LIST'"
      :items="field.options"
      :label="field.name || 'Nombre del campo'"
      :hint="field.description"
      :rules="computedRequired ? requiredRules : []"
      persistent-hint
      filled
      rounded
      :disabled="computedDisabled"
    />
    <v-file-input
      v-if="field.fieldType == 'DOC'"
      :background-color="bgColor || ''"
      :hint="field.description"
      :label="field.name || 'Nombre del campo'"
      :accept="formatAccept(field.accept)"
      :rules="computedRequired ? requiredRules : []"
      prepend-icon=""
      persistent-hint
      filled
      style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;"
      rounded
      v-model="value"
      :disabled="computedDisabled"
    >
      <template v-slot:selection="{text}">
          {{ text || formatFileName(value)}}
      </template>
    </v-file-input>
    <v-menu
      v-if="field.fieldType == 'DATE'"
      :close-on-content-click="true"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="value"
          :label="field.name || 'Nombre del campo'"
          :hint="field.description"
          :rules="computedRequired ? requiredRules : []"
          prepend-inner-icon="mdi-calendar"
          persistent-hint
          filled
          rounded
          v-on="on"
          :disabled="computedDisabled"
        ></v-text-field>
      </template>
      <v-date-picker min="1900-01-01" v-model="value" no-title scrollable> </v-date-picker>
    </v-menu>
    <v-text-field
      v-model="value"
      v-if="field.fieldType == 'NUMBER'"
      :label="field.name || 'Nombre del campo'"
      :hint="field.description"
      :rules="computedRequired ? requiredRules : []"
      persistent-hint
      filled
      rounded
      type="number"
      :disabled="computedDisabled"
    >
    <template v-slot:append v-if="computedEnableAppendIcon">      
      <v-icon 
        @click="showFielInDataSheet"
        :color="computedColorAndIcon.color"
        >{{ computedColorAndIcon.icon }}</v-icon>
    </template>
  </v-text-field>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { colors } from "@/constants/colors.js";
export default {
  name: "formInput",
  props: {
    field: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    bgColor: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      color: colors,
      requiredRules: [(v) => !!v || "Este campo es requerido"],
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    value: {
      get() {
        return this.field.value;
      },
      set(value) {
        this.$emit("input", { index: this.index, value: value });
      },
    },
    computedRequired(){
      if(!this.field.permissions.includes(this.user.role)){
        return false
      }
      return this.field.required
    },
    computedDisabled(){
      if(this.user && this.user.role){
        return !this.field.permissions.includes(this.user.role)
      }
      return !this.field.permissions.includes('MEMBER')
    },
    computedColorAndIcon(){
        return this.field.showInDataSheetByUser ? 
        {color: 'blue', icon: 'mdi-eye'} : {color: 'gray', icon: 'mdi-eye-off'}
    },
    computedEnableAppendIcon(){
      return (this.user.role == 'MEMBER' || this.user.role == 'MEMBERSHIP_USER') && this.field.showInDataSheet
    }
  },
  methods: {
    showFielInDataSheet(){
      this.field.showInDataSheetByUser = !this.field.showInDataSheetByUser
    },
    formatAccept(accept) {
      if (!accept) return "";
      let acceptString = "";
      accept.forEach((item) => {
        if (item.value) {
          acceptString += item.value + ", ";
        } else {
          acceptString += item + ", ";
        }
      });
      return acceptString;
    },
    formatFileName(fileName) {
      let limit = 0;
      if (!fileName) return "";
      // const stringToRemove = 'https://wechamber-v3-files.s3.us-east-2.amazonaws.com/'
      // return fileName.replace(stringToRemove, '')

      this.$vuetify.breakpoint.mdAndDown ? limit = 17 : limit = 60
      const fileNameFromUrl = String(fileName).split('/')
      let nameNew = (fileNameFromUrl[fileNameFromUrl.length - 1]).replaceAll('%20', '_').replaceAll('%CC%81')
      if (nameNew.length > limit) {
        return nameNew.substring(0, limit) + '...'
      }
      return nameNew
    },
  },
};
</script>

<style>
</style>