export default {
  event: {},
  eventSelected: {},
  micrositeData: {},
  organizationEvents: [],
  organizationEventsPaginated: {
    events: [],
    totalDocs: null,
    limit: 8,
    totalPages: null,
    page: 1,
    pagingCounter: null,
    hasPrevPage: false,
    hasNextPage: true,
    prevPage: null,
    nextPage: null
  },
  eventFaqs: [],
  eventList: []
}