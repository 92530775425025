export function setOrganizationEventsPaginated(state, organizationEventsPaginated) {
  // Comprobar si el estado tiene datos de eventos
  if (state.organizationEventsPaginated.totalDocs==null) {
    state.organizationEventsPaginated = organizationEventsPaginated;
  } else {
    state.organizationEventsPaginated.events = state.organizationEventsPaginated.events.concat(organizationEventsPaginated.events);
    state.organizationEventsPaginated.totalDocs = organizationEventsPaginated.totalDocs;
    state.organizationEventsPaginated.limit = organizationEventsPaginated.limit;
    state.organizationEventsPaginated.totalPages = organizationEventsPaginated.totalPages;
    state.organizationEventsPaginated.page = organizationEventsPaginated.page;
    state.organizationEventsPaginated.pagingCounter = organizationEventsPaginated.pagingCounter;
    state.organizationEventsPaginated.hasPrevPage = organizationEventsPaginated.hasPrevPage;
    state.organizationEventsPaginated.hasNextPage = organizationEventsPaginated.hasNextPage;
    state.organizationEventsPaginated.prevPage = organizationEventsPaginated.prevPage;
    state.organizationEventsPaginated.nextPage = organizationEventsPaginated.nextPage;
  }
}

export function setOrganizationEvents(state, organizationEvents) {
  state.organizationEvents = organizationEvents;
}

export function clearOrganizationEventsPaginated(state) {
  state.organizationEventsPaginated = {
    events: [],
    totalDocs: null,
    limit: 8,
    totalPages: null,
    page: 1,
    pagingCounter: null,
    hasPrevPage: false,
    hasNextPage: true,
    prevPage: null,
    nextPage: null
  };
}

export function setEventSelected(state, eventSelected) {
  state.eventSelected = eventSelected;
}

export function setMicrositeData(state, micrositeData) {
  state.micrositeData = micrositeData;
}

export function setEventFaqs(state, eventFaqs) {
  state.eventFaqs = eventFaqs;
}

export function setEventList(state, list) {
  state.eventList = list;
}