<template>
  <v-dialog max-width="90%" transition="dialog-bottom-transition" v-model="formDialog" @click:outside="handleClose()">
    <template v-slot:activator="{ on, attrs }">
      <!-- add new member button in /Mis mienbros -->
      <v-btn v-if="$route.fullPath.includes('/miembros') && create" v-bind="attrs" v-on="on" color="accent"
        class="rounded-xl" :small="$vuetify.breakpoint.xsOnly" @click="handleCreate()">
        <span class="font-weight-bold black--text">+ Nuevo miembro</span>
      </v-btn>

      <!-- edit member information in /Mis miembros -->
      <v-btn v-else-if="$route.fullPath.includes('/miembros') && edit" fab small elevation="1" v-on="on" v-bind="attrs"
        @click="handleEdit()">
        <v-icon small> mdi-pencil </v-icon>
      </v-btn>

      <!-- user avatar in Member accounts -->
      <v-sheet v-else elevation="1" v-bind="attrs" v-on="on" @click="handleEdit()" color="white"
        style="border-radius: 50px" class="d-flex justify-center align-center">
        <span :cols="$vuetify.breakpoint.mdAndDown ? '12' : 'auto'"
          class="wDarkerBlue--text text-uppercase font-weight-bold mx-5 my-3"
          style="display: inline-block; text-align: center">
          Editar mi perfil
        </span>
      </v-sheet>
    </template>

    <v-card color="white" class="pa-4">
      <!-- Form user header -->
      <div class="d-flex justify-center justify-space-between pt-4">
        <v-row>
          <v-col cols="6" class="pt-0">
            <v-btn elevation="0" text small fab @click="handleClose()">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-col>

          <v-col cols="12" class="d-flex justify-center" v-if="edit && $route.fullPath.includes('/member')">
            <ImageUploader v-if="profilePicture &&
    profilePicture != '' &&
    profilePicture != null &&
    profilePicture != undefined &&
    profilePicture != 'NOT_ASSIGNED'
    " :imgUrl="profilePicture" class="mb-5" @save="handleImage" />
            <ImageUploader v-else
              imgUrl="https://w7.pngwing.com/pngs/211/382/png-transparent-silhouette-avatar-avatar-silhouettes-animals-public-relations-monochrome.png"
              class="mb-5" @save="handleImage" />
          </v-col>
        </v-row>
      </div>
      <!-- Form user title -->

      <v-tabs :color="color.primaryVariant" v-model="tab" fixed-tabs>
        <v-tab v-for="(item, i) in tabItemsComputed" :key="i" :href="`#${item.href}`">
          <span class="wc-caption-2 font-weight-bold">{{ item.name }}</span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <!-- Datos de miembro -->
        <v-tab-item value="memberData">
          <v-form ref="formuser" v-if="showDatosMiembroSection">
            <v-row class="ma-0">
              <!-- Branch of the user -->
              <v-col cols="12" class="mt-4 py-0">
                <div class="d-flex justify-end">
                  <v-btn rounded color="primary" @click="handleSave()" v-if="showSaveButton == true">
                    <v-icon>mdi-content-save</v-icon>
                    Guardar
                  </v-btn>
                </div>
                <p>
                  Si no puedes visualizar el logotipo intenta cerrando e
                  iniciando sesión
                </p>
              </v-col>
              <v-col cols="12" md="4" class="pb-0" v-if="(create || edit) &&
    (user.role != 'BRANCH' || user.role != 'ORGANIZATION_USER') &&
    organizationHierarchy == 'TWO_LEVELS'
    ">
                <v-combobox v-model="MemberInfo.branch" label="Sucursal" :items="branches" item-text="company"
                  item-value="_id" filled dense rounded :append-icon="create ? '$dropdown' : ''"
                  :disabled="create ? false : true" :loading="loadingBranches"
                  :rules="[(v) => !!v || 'Sucursal es requerida']" class="ma-0 pa-0" />
              </v-col>

              <!--Basic Info of the user-->
              <v-col cols="12" md="4" class="pb-0" v-for="(item, index) in basicInfo" :key="index">
                <v-text-field v-model="MemberInfo[item.field]" :label="item.label" :rules="item.required
    ? [(v) => !!v || `${item.label} es requerido`]
    : []
    " :disabled="disabledBasicField(item.field)" filled dense rounded>
                </v-text-field>
              </v-col>

              <v-col cols="12" md="4" class="pb-0" v-if="create">
                <v-text-field v-model="MemberInfo.password" label="Contraseña"
                  :rules="[(v) => !!v || `Contraseña es requerido`]" filled dense rounded />
              </v-col>

              <!-- Extra info of the user -->
              <v-col cols="12" v-if="loadingFields">
                <v-progress-linear height="10" indeterminate color="accent"></v-progress-linear>
              </v-col>
              <v-col cols="12" md="4" class="pb-0" v-for="(field, i) in MemberInfo.memberData"
                :key="field.formattedName">
                <FormInput :field="field" :index="i" @input="inputHandler" />
              </v-col>
              <!-- Subgrupo combobox
                <v-col cols="12" md="4" class="pb-0"  v-if="
                    (create || edit) &&
                    (user.role != 'BRANCH' ||
                      user.role != 'ORGANIZATION_USER')
                  ">
                  <v-combobox
                    v-model="MemberInfo.subgroup"
                    label="Subgrupo"
                    :items="subgroups"
                    hide-selected
                    background-color="#f9fafc"
                    persistent-hint
                    hide-details
                    small-chips
                    filled
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            Presiona <kbd>enter</kbd> para crear un nuevo
                            subgrupo.
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                </v-col>-->
            </v-row>
          </v-form>
          <v-card v-if="user.role == 'MEMBER' || user.role == 'MEMBERSHIP_USER'" elevation="0" class="mt-3">
            <v-card-text :class="$vuetify.breakpoint.mdAndDown ? 'text-center' : 'text-lext'
    " class="section-title pa-5 pb-1">
              Cambiar contraseña
            </v-card-text>
            <v-card class="mx-5 pa-0" elevation="1">
              <v-row class="ma-0">
                <v-col v-for="(field, i) in passwordFields" :key="i">
                  <v-text-field :ref="`textfield${i}`" v-model="field.content" :label="field.label"
                    :append-icon="field.show ? 'mdi-eye' : 'mdi-eye-off'" :type="field.show ? 'text' : 'password'"
                    :rules="i === 2 ? confirmPassword : field.rules" @click:append="field.show = !field.show" filled
                    dense rounded>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card>
          </v-card>
          <v-card v-if="user.role == 'BRANCH' && edit" class="mx-5 mt-5 pa-0" elevation="0">
            <AffiliateLink :memberID="member._id" />
          </v-card>

          <br /><br />

          <v-row v-if="!edit &
    (this.user.id == '62b622c66a7441ae20159d7d' ||
      this.user.id == '6297b0fec7bf8b6f826313be')
    " justify="center" no-gutters class="ma-2">
            <v-col md="3" pl="6">
              <v-row align="center">
                <v-btn class="rounded-xl" :small="$vuetify.breakpoint.xsOnly" color="green" @click="handleCreateDev()">
                  <span class="font-weight-bold black--text">Guardar como Desarrollador</span>
                </v-btn>

                <div class="ml-2 mt-4" style="padding-bottom: 15px">
                  <!-- Ajustar el padding aquí -->
                  <v-tooltip top max-width="350px">
                    <template v-slot:activator="{ on }">
                      <v-icon color="primary" dark v-on="on">mdi-help-circle</v-icon>
                    </template>
                    <span>¿Qué es un desarrollador? Es un tipo de usuario dentro de
                      la comunidad AMPI dedicado al desarrollo (construcción) de
                      vivienda o bienes inmuebles. Un “desarrollador” no paga
                      cuota alguna a la Asociación pero sí tiene acceso al
                      portal de VALORES AMPI.</span>
                  </v-tooltip>
                </div>
              </v-row>
            </v-col>

            <v-col md="3" pl="6">
              <span class="pr-8">Nuevo Estatus</span>

              <v-chip :color="homologateStatusWithColor(this.newValidationStatus).color
    " class="rounded-lg font-weight-bold">
                {{ homologateStatusWithColor(this.newValidationStatus).status }}
              </v-chip>
            </v-col>
          </v-row>
        </v-tab-item>

        <!-- Datos Fiscales -->
        <v-tab-item value="memberFiscalData">
          <v-card elevation="0" class="px-12 pt-6 my-4">
            <div class="d-flex justify-end">
              <v-btn rounded color="primary" @click="handleSave()" v-if="showSaveButton == true">
                <v-icon>mdi-content-save</v-icon>
                Guardar
              </v-btn>
            </div>
            <br />
            <p>
              Los siguientes datos fiscales son los que el sistema mostrará en
              automático cada vez que el miembro ACTIVO desee facturar una
              compra. <br />
              Asegúrate de que los datos sea correctos.
            </p>

            <v-row class="mt-10">
              <v-col :cols="$vuetify.breakpoint.mdAndUp ? 4 : 12" v-for="(item, key) in fiscalDataFormat" :key="key">
                <v-select v-if="key === 'rf'" filled rounded :items="fiscalRegimenItems" :label="fiscalDataFormat[key]"
                  v-model="MemberInfo.fiscalData[key]">
                </v-select>
                <v-text-field v-else :rules="fiscalDataValidations[key]" :label="fiscalDataFormat[key]"
                  v-model="MemberInfo.fiscalData[key]" filled rounded>
                </v-text-field>
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>

        <v-tab-item value="manageEventPoints">
          <manage-event-points :member="member._id" ref="eventPoints"></manage-event-points>
        </v-tab-item>

        <!-- Adeudos -->
        <v-tab-item value="memberDebts">
          <v-form>
            <v-row no-gutters class="pl-5">
              <v-col md="6">
                <span class="text-subtitle-2 grey--text font-weight-bold">En este apartado podrás gestionar los adeudos
                  pendientes del
                  miembro si aplica el caso.</span>
              </v-col>
              <v-col md="6">
                <!--  <span class="text-subtitle-2 grey--text font-weight-bold" >Histórico de adeudos anteriores</span>-->
              </v-col>
            </v-row>
            <v-row no-gutters class="pt-14 pl-5">
              <v-col md="6">
                <span class="pr-8">Estatus Actual</span>

                <v-chip :color="homologateStatusWithColor(MemberInfo.validationStatus).color
    " class="rounded-lg font-weight-bold">
                  {{
    homologateStatusWithColor(MemberInfo.validationStatus)
      .status
  }}
                </v-chip>
              </v-col>
              <!--
              <v-col md="6">
                <span>Adeudo 2023</span>
                <span class="pl-16 grey--text font-weight-bold"></span>
              </v-col>-->
            </v-row>
            <v-row justify="center" align="center" class="ma-0" v-for="(debt, index) in debts" :key="debt._id">
              <v-row justify="center" align="center">
                <v-col cols="10" sm="6" md="4">
                  <v-text-field v-model="debt.description" dense label="Descripción" solo :readonly="!debt.edit" />

                  <v-text-field v-model="debt.amount" :readonly="!debt.edit" filled dense label="Monto" rounded />
                </v-col>
                <v-btn class="mx-2" fab small elevation="1" color="amber" @click="handleEditDebt(index)"
                  v-if="!debt.edit">
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
                <v-btn class="mx-2" fab small elevation="1" color="green" @click="editDebt(debt._id, index)" v-else>
                  <v-icon small>mdi-content-save-outline</v-icon>
                </v-btn>
                <v-btn class="mx-2" fab small elevation="1" color="red" @click="deleteDebt(debt._id, index)"
                  v-show="!debt.edit">
                  <v-icon small> mdi-trash-can-outline </v-icon>
                </v-btn>
              </v-row>
            </v-row>
            <div v-if="createNewDebt">
              <v-row justify="center" no-gutters class="ma-2">
                <v-col cols="10" sm="6" md="4">
                  <v-text-field label="Concepto" v-model="newDebt.description" dense />

                  <v-text-field label="Monto" value="0" v-model="newDebt.amount" filled dense rounded />
                </v-col>
              </v-row>

              <v-row justify="center" no-gutters class="ma-2">
                <v-btn class="rounded-xl mx-2" :small="$vuetify.breakpoint.xsOnly" color="green" @click="createDebt()">
                  <span class="font-weight-bold black--text">Guardar</span>
                </v-btn>

                <v-btn class="rounded-xl mx-2" :small="$vuetify.breakpoint.xsOnly" color="red"
                  @click="cancelCreateDebt()">
                  <span class="font-weight-bold black--text">Cancelar</span>
                </v-btn>
              </v-row>
            </div>

            <v-row justify="center" no-gutters class="ma-2" v-else>
              <v-btn class="rounded-xl" :small="$vuetify.breakpoint.xsOnly" color="green" @click="handleCreateDebt()">
                <span class="font-weight-bold black--text">+ Nueva deuda</span>
              </v-btn>
            </v-row>

            <v-row no-gutters class="pt-7 pl-5 pb-5">
              <v-col md="6">
                <span class="d-flex align-center">
                  <span class="mr-2">Cuota pendiente total: </span>
                  <span class="text-body-2">
                    $ {{ formatAmount(totalAmount) }}
                  </span>
                </span>
              </v-col>
            </v-row>

            <!--
            <v-row no-gutters class="pt-7 pl-5">
              <v-col md="6">
                <span >Monto:</span>
              </v-col>
            </v-row>
            <v-row no-gutters class="pt-7 pl-5">
              <v-col md="6">
                <span >Concepto:</span>
              </v-col>
            </v-row>
            -->
          </v-form>
        </v-tab-item>

        <v-tab-item value="directoryData">
          <DirectoryDataTab :user="user"></DirectoryDataTab>
        </v-tab-item>

        <!--DIALOG DEMO-->
        <v-dialog v-model="dialogDemo" persistent max-width="290">
          <v-card>
            <v-card-title class="text-h5">Confirmación</v-card-title>
            <v-card-text>Estás seguro de querer continuar?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="font-weight-bold black--text mx-2" color="red" rounded elevation="0" text
                @click="dialogDemo = false">Cancelar</v-btn>
              <v-btn class="font-weight-bold black--text mx-2" color="primary" rounded elevation="0" text
                @click="handleCreateDev">Continuar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <personalized-dialog hidden :hide-name="!member.confirmDelete" hide-image :disableContinueButton="member.confirmDelete && member.authDelete === false
    " :closeDialogOnContinue="member.confirmDelete && member.authDelete" :dialog="member.deleteDialog"
          @save="deleteMemberUser(index)" @discard="closeDeleteDialog(index)">
          <template #alertName v-if="member.confirmDelete">
            <p class="pa-0 ma-0 d-flex align-center justify-center">
              <v-icon size="30" class="pr-4" color="red">
                mdi-alert-outline
              </v-icon>
              Eliminar miembro
            </p>
          </template>
          <template #alertContent>
            <v-row v-if="!member.confirmDelete">
              <v-col cols="2" class="d-flex align-center justify-center">
                <v-icon size="40" color="red"> mdi-alert-outline </v-icon>
              </v-col>
              <v-col>
                <p class="title text-left" style="word-break: break-word">
                  Estás a punto de eliminar al miembro
                  <span style="color: black; font-weight: 600">{{
    `${member.name} ${member.firstLastname}`
  }}</span>
                  y su información no podrá ser recuperada, ¿deseas continuar
                  con este proceso?.
                </p>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="auto" class="d-flex align-center justify-center">
                <v-checkbox color="#1b273e" v-model="member.authDelete" hide-details>
                  <template #label>
                    <p class="title text-left" style="word-break: break-word">
                      Estoy enterado de que el miembro
                      <span style="color: black; font-weight: 600">{{
    `${member.name} ${member.firstLastname}`
  }}</span>
                      no se podrá recuperar una vez se haya eliminado.
                    </p>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
          </template>
          <template #button>
            {{ !member.confirmDelete ? "Continuar" : "Eliminar" }}
          </template>
        </personalized-dialog>

        <!-- Precio de membresia-->
        <v-tab-item value="membershipPrice">
          <v-form>
            <v-col md="6">
              <span class="text-subtitle-2 grey--text font-weight-bold pt-5">
                En este apartado podrás gestionar los precios de las membresías
                del miembro.
              </span>
            </v-col>

            <v-row class="ma-0">
              <v-col cols="10" sm="6" md="4">
                <v-select v-model="MemberInfo.assignedMembership" :disabled="!isMembershipPriceEditable"
                  :items="affiliationTypes" item-text="name" item-value="_id" label="Tipo de membresía" filled dense
                  rounded>
                </v-select>
              </v-col>
              <v-col cols="10" sm="6" md="4">
                <v-text-field v-model="MemberInfo.membershipPrice" :disabled="!isMembershipPriceEditable" type="number"
                  dense label="Monto" value="0" filled rounded @keyup="validateMembershipPrice()" />
              </v-col>
              <v-col md="4">
                <v-btn class="mx-2" fab small elevation="1" color="amber" v-if="!isMembershipPriceEditable"
                  @click="handleEditMembershipPrice">
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
                <v-btn class="mx-2" fab small elevation="1" color="green" v-else @click="updateMembershipPrice">
                  <v-icon small>mdi-content-save-outline</v-icon>
                </v-btn>
              </v-col>
              <div class="pa-0 ma-0" v-if="isImefOrganization(user._id, user.organization)">
                <v-col cols="12" md="12">
                  <span class="text-subtitle-2 grey--text font-weight-bold pt-5">
                    Puedes editar los datos de facturación para este nivel de membresía asignado al usuario.
                  </span>
                </v-col>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-select label="Grupo" rounded dense filled :disabled="!isMembershipPriceEditable"
                        v-model="imefForm['group']" :items="formatGroupsItems(IMEF_GROUPS)" />
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field label="Almacén" rounded dense filled :disabled="!isMembershipPriceEditable"
                        v-model="imefForm['almacen']" />
                      <!-- Add rules: up to 15 characters -->
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field v-model="imefForm['serviceCode']" label="Código de servicio" rounded dense filled
                        :disabled="!isMembershipPriceEditable" />
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field label="Serie" rounded dense filled :disabled="!isMembershipPriceEditable"
                        v-model="imefForm['serie']" />
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field label="Segmento Contable" rounded dense filled
                        :disabled="!isMembershipPriceEditable" v-model="imefForm['accountingSegment']" />
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field label="Lugar de expedición" rounded dense filled
                        :disabled="!isMembershipPriceEditable" v-model="imefForm['zipCode']" />
                    </v-col>
                    </v-row>
                  </v-col>
                  </div>
                </v-row>
                </v-form>
              </v-tab-item>

        <!-- Gestionar contraseña -->
        <v-tab-item value="passwordRecovery" class="px-12">
          <ResetPasswordForm
            :memberId="member._id"
            :memberEmail="member.email"
            :memberStatus="member.validationStatus"
            :availableAffiliationTypes="affiliationTypes"
            @update="emitUpdate()"
          />
        </v-tab-item>
      </v-tabs-items>

      <!-- <v-card-text>
          <v-row no-gutters>
            <v-col
              :class="[
                'pa-3',
                'pr-5',
                'clickable-text',
                'text-md',
                { 'underline-active': datosMiembroActive },
              ]"
              @click="toggleDatosMiembro"
            >
              Datos de
              <span v-if="$route.fullPath.includes('/miembros') && create"
                >nuevo</span
              >
              miembro
            </v-col>

            <v-col
              v-if="($route.fullPath.includes('/miembros') && !create)"
              :class="[
                'pa-3',
                'pl-5',
                'clickable-text',
                'text-md',
                { 'underline-active': adeudosActive },
              ]"
              @click="toggleAdeudos"
            >

              Gestionar Adeudos
            </v-col>
          </v-row>
        </v-card-text> -->
    </v-card>
  </v-dialog>

  <!-- <v-dialog v-model="deleteDebtDialog" width="600">
      <v-card>
        <v-card-text>
          <v-row justify="center"> </v-row>
          <v-row>
            <v-col cols="12" class="mt-2">
              <p class="text-center font-weight-bold headline mb-0">
                Eliminar Deuda
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <p class="title text-center mb-0">
                ¿Está seguro que quiere eliminar la deuda seleccionada?
              </p>
            </v-col>
          </v-row>
          <v-row
            class="d-flex pb-2 mt-5"
            :class="
              $vuetify.breakpoint.smAndUp ? 'justify-end' : 'justify-center'
            "
          >
            <v-btn
              class="font-weight-bold black--text mx-2"
              color="grey lighten-3"
              rounded
              elevation="0"
              @click="deleteDebtDialog = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              class="font-weight-bold black--text mx-2"
              color="accent"
              rounded
              elevation="0"
              @click="deleteDebt()"
            >
              Aceptar
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>-->
</template>
<script>
import axios from 'axios';
import { mapActions, mapMutations, mapState } from 'vuex';
import FormInput from '@/components/shared/FormInput.vue';
import { imageHelper } from '@/utils/imageProcessing.js';
import ImageUploader from '@/components/shared/ImageUploader';
import AffiliateLink from '@/components/memberDashboard/affiliates/affiliateLink.vue';
import { validations } from '@/share/validations.js';
import ResetPasswordForm from './ResetPasswordForm.vue';
import { homologateStatusWithColor } from '@/common/hook/useHelper.js';
import Vue from 'vue';
import { colors } from '@/constants/colors.js';
import { fiscalRegimenItems } from '@/utils/fiscalRegimen';
import DirectoryDataTab from './DirectoryDataTab.vue';
import { isImefOrganization, formatGroupsItems, formatServiceItems, IMEF_GROUPS, IMEF_SERVICES } from '../../share/imef';
import manageEventPoints from './memberFormComponents/manageEventPoints.vue';
import PersonalizedDialog from './PersonalizedDialog.vue';

export default {
  components: {
    FormInput,
    ImageUploader,
    AffiliateLink,
    PersonalizedDialog,
    DirectoryDataTab,
    ResetPasswordForm,
    manageEventPoints
  },
  props: {
    create: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    member: {
      type: Object,
      default: () => ({}),
    },
    profilePicture: {
      type: String,
      default: "",
    },
    colorProp: {
      type: String,
      default: "accent",
    },
    textColor: {
      type: String,
      default: "black",
    },
  },
  data() {
    return {
      IMEF_GROUPS,
      IMEF_SERVICES,
      subgroups: [],
      dialogDemo: false,
      affiliationTypes: [],
      newValidationStatus: "PROSPECTO",
      isMembershipPriceEditable: false,
      editedMembershipPrice: "",
      debts: [],
      newDebt: {
        amount: "0",
        description: "",
      },
      showImefForm: false,
      imefForm: {
        group: "",
        almacen: "",
        serviceCode: "",
        serie: "",
        accountingSegment: "",
        zipCode: ""
      },
      fiscalRegimenItems,
      showSaveButton: true,
      createNewDebt: false,
      isChapter: false,
      searchBarLoader: false,
      dialogMember: false,
      activator: null,
      attach: null,
      editing: null,
      showPass: false,
      editingIndex: -1,
      showDatosMiembroSection: true,
      showAdeudosSection: false,
      adeudosActive: false,
      datosMiembroActive: true,
      formDialog: false,
      organization: "",
      order: {},
      tabValue: 0,
      tab: null,
      tabItems: [
        { name: 'Datos de Miembro', href: 'memberData' },
        { name: 'Datos Fiscales', href: 'memberFiscalData' },
        { name: 'Eventos', href: 'manageEventPoints' }
      ],
      fiscalDataFormat: {
        legalName: "Razón Social",
        taxId: "RFC",
        email: "Correo Electrónico",
        rf: "Régimen Fiscal",
        zipCode: "Código Postal",
        street: "Calle",
        exterior: "Número Exterior",
        interior: "Número Interior",
        neighborhood: "Colonia",
        municipality: "Municipio o delegación",
        state: "Estado",
      },

      fiscalDataValidations: {
        legalName: validations.generalFValidation({
          required: false,
          name: "razón social",
        }),
        taxId: validations.generalMValidation({
          required: false,
          name: "RFC",
          minLength: 12,
          maxLength: 13,
        }),
        street: validations.generalMValidation({
          required: false,
          name: "domicilio",
        }),
        zipCode: validations.generalMValidation({
          required: false,
          name: "código postal",
          minLength: 5,
        }),
        rf: validations.generalMValidation({
          required: false,
          name: "Regimen Fiscal",
        }),
        email: validations.generalMValidation({
          required: false,
          name: "e-mail",
        }),
      },
      debtStep: 0,
      color: colors,
      branches: [],
      imageToUpdate: null,

      MemberInfo: {
        membershipPrice: 0,
        assignedMembership: "",
        validationStatus: "",

        email: "",
        password: "",
        firstLastname: "",
        secondLastname: "",
        name: "",
        branch: "",
        numero_de_telefono: "",
        phone: "",
        memberData: [],
        fiscalData: {},
        stars: "3",
        subgroup: "",
      },

      basicInfo: [
        { label: "Nombres", field: "name", required: true },
        { label: "Apellidos", field: "firstLastname", required: true },
        { label: "Correo electrónico", field: "email", required: true },
        { label: "Empresa / Razón social", field: "company", required: true },
        { label: "Teléfono", field: "phone", required: false },
      ],
      passwordFields: [
        {
          label: "Contraseña actual",
          content: "",
          show: false,
          rules: [
            ...validations.password({ name: "contraseña", required: false }),
          ],
        },
        {
          label: "Nueva contraseña",
          content: "",
          show: false,
          rules: [...validations.newPasswordRule()],
        },
        {
          label: "Verificar nueva contraseña",
          content: "",
          show: false,
          rules: [],
        },
      ],

      //loadings
      loadingBranches: false,
      loadingFields: false,
      loadingSave: false,
    };
  },
  computed: {
    ...mapState("user", ["user"]),

    tabItemsComputed() {
      let tabItems = [
        { name: 'Datos de Miembro', href: 'memberData' },
        { name: 'Datos Fiscales', href: 'memberFiscalData' },
      ];

      if (this.isImefOrganization(this.user._id, this.user.organization)) {
        tabItems.push( { name: 'Eventos', href: 'manageEventPoints' })
      }

      if (this.$route.fullPath.includes("/miembros") && !this.create) {
        tabItems.push({ name: "Gestionar Adeudos", href: "memberDebts" });
      }

      if (this.user.role == "MEMBER" || this.user.role == "MEMBERSHIP_USER") {
        tabItems.push({
          name: "Datos del Directorio",
          href: "directoryData",
        });
      } else {
        const options = [
        {
          name: "Gestionar Precio de Membresía",
          href: "membershipPrice",
        },
        {
          name: "Configuración del miembro",
          href: "passwordRecovery",
        },
      ]
        options.forEach((option) => tabItems.push(option))
      }

      return tabItems;
    },
    organizationHierarchy() {
      if (this.user.role == "HEAD_OFFICE") {
        return this.user.hierarchyType || "TWO_LEVELS";
      }
      if (this.user.role == "MEMBER" || this.user.role == "MEMBERSHIP_USER") {
        return this.user.headOffice.hierarchyType || "TWO_LEVELS";
      }
      return "TWO_LEVELS";
    },
    confirmPassword() {
      return [
        this.passwordFields[1].content === this.passwordFields[2].content ||
        "*Las Contraseñas deben ser iguales",
      ];
    },
    hasDebts() {
      return this.debts.length > 0;
    },

    totalAmount() {
      let total = 0;
      this.debts.forEach((debt) => {
        total += debt.amount;
      });
      return total;
    },
  },
  methods: {
    ...mapMutations('notification', ['show']),
    ...mapMutations('loader', ['loading', 'loaded']),
    ...mapMutations('user', ['setUserProfilePicture']),
    ...mapActions('registrationFields', ['getRegistrationFields']),
    ...mapActions('debts', ['getDebt']),
    ...mapActions('branches', ['getBranches']),
    ...mapActions('member', ['updatePassword', 'fetchMemberCredentials']),
    ...mapActions('membershipUsers', ['fetchMembershipCredentials']),
    ...mapActions('user', ['login']),
    ...mapActions('debts', ['fetchDebt']),
    isImefOrganization,
    formatGroupsItems,
    formatServiceItems,
    homologateStatusWithColor,
    disabledBasicField(field) {
      const fieldsToDisable = ['name', 'firstLastname'];
      return this.user.headOffice && this.user.headOffice.id == '66fb2d896646ca001cb93b3f' && fieldsToDisable.includes(field);
    },
    async handleEditMembershipPrice() {
      // await this.getAffiliationTypes();
      this.isMembershipPriceEditable = true;
      this.editedMembershipPrice = this.membershipPrice;
    },
    async fetchSubgroups() {
      try {
        const response = await axios.get("/users/subgroups");

        this.subgroups = response.data;
      } catch (error) {
        console.error("Error al obtener los subgrupos:", error);
      }
    },
    async fetchEmails(selectedSubgroup) {
      try {
        const response = await axios.get(
          `/users/emails?subgroup=${selectedSubgroup}`
        );
        return response.data;
      } catch (error) {
        console.error("Error fetching emails:", error);
        this.show({
          color: "error",
          text: "Error al obtener los correos electrónicos.",
        });
        return [];
      }
    },
    // saveMembershipPrice() {
    //   this.isMembershipPriceEditable = false;
    //   // Aquí puedes agregar lógica para guardar el nuevo valor
    //   // this.editedMembershipPrice
    // },
    confirmCreateDev() {
      this.dialogDemo = true;
    },

    async getAffiliationTypes() {
      try {
        const response = await Vue.axios({
          method: "GET",
          url: "affiliation-types/organization",
        });

        this.affiliationTypes = response.data.affiliationTypes;
      } catch (error) {
        console.log(error);
      }
    },

    async createDebt() {
      try {
        const response = await Vue.axios({
          method: "POST",
          url: "debts/" + this.MemberInfo._id,
          data: {
            amount: this.newDebt.amount,
            description: this.newDebt.description,
          },
        });

        if (response.status === 200) {
          this.show({
            text: "Deuda actualizada correctamente",
            color: "primary",
          });
          this.debts.push(response.data.debt);
        } else {
          this.show({
            text: "Favor de no dejar campos vacíos",
            color: "error",
          });
        }
      } catch (error) {
        this.show({
          text: "Favor de no dejar campos vacíos",
          color: "error",
        });
      } finally {
        this.createNewDebt = false;
        this.newDebt = {
          amount: "",
          description: "",
        };
      }
    },

    cancelCreateDebt() {
      this.createNewDebt = false;
      this.newDebt = {
        amount: "",
        description: "",
      };
    },

    cancelEditDebt() {
      this.editDebt = false;
    },

    handleCreateDebt() {
      this.createNewDebt = true;
    },
    handleCreateDev() {
      this.newValidationStatus = "DESARROLLADOR";
      console.log(this.newValidationStatus);
      this.dialogDemo = false;
    },

    handleEditDebt(index) {
      Vue.set(this.debts, index, {
        ...this.debts[index],
        edit: true,
      });
    },

    async editDebt(debtID, index) {
      try {
        const response = await Vue.axios({
          method: "PATCH",
          url: "debts/" + debtID,
          data: {
            amount: this.debts[index].amount,
            description: this.debts[index].description,
          },
        });

        if (response.status === 200) {
          this.show({
            text: "Deuda actualizada correctamente",
            color: "primary",
          });
        } else {
          this.show({
            text: "Favor de no dejar campos vacíos",
            color: "error",
          });
        }
      } catch (error) {
        this.show({
          text: "Favor de no dejar campos vacíos",
          color: "error",
        });
      } finally {
        this.debts[index].edit = false;
      }
    },

    async getMemberDebtsById(tempID) {
      try {
        const { data } = await this.axios({
          url: "debts/" + tempID,
          method: "GET",
        });

        return { status: true, data: data.debts };
      } catch (error) {
        return { status: false, message: error };
      }
    },

    async deleteDebt(debtID, index) {
      try {
        const response = await Vue.axios({
          method: "DELETE",
          url: "debts/" + debtID,
        });

        if (response.status === 200) {
          this.show({
            text: "Deuda eliminada correctamente",
            color: "primary",
          });
          this.debts.splice(index, 1);
        } else {
          this.show({
            text: "Error al eliminar la deuda",
            color: "error",
          });
        }
      } catch (error) {
        this.show({
          text: "Error al eliminar la deuda",
          color: "error",
        });
      }
    },

    async updateDebt(debtID, debt) {
      try {
        const orderResponse = await this.axios({
          method: "PATCH",
          url: "debts/" + debtID,
          data: { amount: debt.amount, description: debt.description },
        });

        this.order = orderResponse.data.order;
      } catch (error) {
        console.log(error);
      }
    },

    formatAmount(amount) {
      let formattedAmount = amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      formattedAmount += ".00";
      return formattedAmount;
    },

    /*created() {
    this.getMemberDebts();
  },*/

    toggleDatosMiembro() {
      if (!this.datosMiembroActive) {
        this.showSaveButton = true;
        this.showAdeudosSection = false;
        this.showDatosMiembroSection = true;
        this.datosMiembroActive = true;
        this.adeudosActive = false;
      }
    },
    toggleAdeudos() {
      if (!this.adeudosActive) {
        this.showSaveButton = false;
        this.showDatosMiembroSection = false;
        this.adeudosActive = true;
        this.showAdeudosSection = true;
        this.datosMiembroActive = false;
      }
    },

    //handle select image
    handleImage(file, resetState) {
      this.imageToUpdate = file;
    },
    //handle uddate profile picture
    async handleImageUpdate() {
      if (this.imageToUpdate != null) {
        const formData = new FormData();
        formData.append("file", this.imageToUpdate);
        const response = await this.axios.put(
          "/users/update-profile-picture",
          formData
        );
        if (response.status === 200) {
          this.show({
            color: "primary",
            text: "¡La imagen se ha actualizado correctamente!",
          });
          this.profilePicture = response.data?.user?.profilePicture || '';
          this.setUserProfilePicture(this.profilePicture);
        }
      }
    },
    //upload docs and update the value of the field
    async formatDocs() {
      for (let i = 0; i < this.MemberInfo.memberData.length; i++) {
        if (
          this.MemberInfo.memberData[i].fieldType == "DOC" &&
          this.MemberInfo.memberData[i].value &&
          this.MemberInfo.memberData[i].value != null &&
          this.MemberInfo.memberData[i].value != undefined
        ) {
          // if value is not a string
          // console.log(typeof this.MemberInfo.memberData[i].value);
          if (typeof this.MemberInfo.memberData[i].value != "string") {
            this.MemberInfo.memberData[i].value = await imageHelper.saveImage(
              this.MemberInfo.memberData[i].value
            );
          }
        }
        delete this.MemberInfo.memberData[i].organization;
        delete this.MemberInfo.memberData[i].permissions;
      }
    },

    //handle open dialog to create
    async handleCreate() {
      //get list of fields
      await this.fetchSubgroups();

      console.log(this.organization);
      this.loadingFields = true;
      const fields = await this.getRegistrationFields(this.organization);

      if (fields.status) {
        this.MemberInfo.memberData = fields.data.data;
      }
      this.loadingFields = false;

      //get list of branches
      this.loadingBranches = true;
      const response = await this.getBranches();
      this.branches = response.branches;
      this.loadingBranches = false;
    },

    //handle open dialog to edit
    async handleEdit() {
      this.loadingFields = true;
      //get list of fields
      console.log(this.organization);
      const fields = await this.getRegistrationFields(this.organization);

      // assign fiscal Data if doesn't exist in database
      if (!this.member.fiscalData) {
        this.member.fiscalData = {
          legalName: "",
          taxId: "",
          email: "",
          rf: "",
          zipCode: "",
          street: "",
          exterior: "",
          interior: "",
          neighborhood: "",
          municipality: "",
          state: "",
        };
      }

      //set member info
      if (this.member.memberData) {
        for (let i = 0; i < fields.data.data.length; i++) {
          for (let j = 0; j < this.member.memberData.length; j++) {
            if (
              fields.data.data[i].formattedName ===
              this.member.memberData[j].formattedName
            ) {
              fields.data.data[i].value = this.member.memberData[j].value;
              fields.data.data[i].showInDataSheetByUser =
                this.member.memberData[j].showInDataSheetByUser;
            }
          }
        }
        // this.getMemberDebtsById();
      }
      // Update MemberInfo with member data
      this.MemberInfo = this.member;
      this.MemberInfo.memberData = fields.data.data;

      // this.MemberInfo.fiscalData.rf = parseInt(this.MemberInfo.fiscalData.rf)
      this.MemberInfo = {
        ...this.member,
        memberData: fields.data.data,
        subgroup: this.member.subgroup || "", // Asigna el valor del subgrupo
      };

      let tempID = this.member._id;

      let response = await this.getMemberDebtsById(tempID);
      this.debts = Object.assign(this.debts, response.data);
      this.loadingFields = false;
      //console.log(this.MemberInfo)
      //console.log("SIM", this.MemberInfo.membershipPrice)

      const fieldsWithOrder = this.MemberInfo.memberData.filter(
        (field) => field.order != null
      );
      const fieldsWithoutOrder = this.MemberInfo.memberData.filter(
        (field) => field.order == null
      );

      fieldsWithOrder.sort((a, b) => a.order - b.order);

      let orderedFields = [...fieldsWithoutOrder];

      for (const field of fieldsWithOrder) {
        const position = field.order - 1;
        orderedFields.splice(position, 0, field);
      }
      await this.fetchSubgroups();
      this.MemberInfo.memberData = orderedFields;
      this.showImefForm = true;
    },

    //handle close dialog
    handleClose() {
      this.MemberInfo = {
        email: "",
        password: "",
        firstLastname: "",
        secondLastname: "",
        name: "",
        branch: "",
        numero_de_telefono: "",
        phone: "",
        memberData: [],
        debts: [],
        fiscalData: {},
      };
      this.formDialog = false;
      this.imefForm = {
        group: "",
        almacen: "",
        serviceCode: "",
        serie: "",
        accountingSegment: "",
        zipCode: ""
      }
    },
    HandleDeleteDebt() {
      this.deleteDebt(this.debts._id);
    },

    HandleEditDebt(index) {
      this.debts[index].edit = true;
    },

    //handle v-model of the extra fields
    inputHandler({ value, index }) {
      this.MemberInfo.memberData[index].value = value;
    },

    //handle save button
    async handleSave() {
      this.loadingSave = true;
      if (this.create) {
        await this.createNewMember();
      } else if (this.edit) {
        await this.updateMember();
        //await this.updateDebt();
      }
      this.loadingSave = false;
    },

    //creates a new member
    async createNewMember() {
      // console.log(this.newValidationStatus);
      //console.log("stars");
      //console.log(this.MemberInfo.stars);
      const valid = await this.$refs.formuser.validate();
      if (!valid) return;
      await this.formatDocs();
      try {
        let postUser = {
          isRequireAuthorization: true,
          role: "MEMBER",
          company: this.MemberInfo.company,
          email: this.MemberInfo.email,
          password: this.MemberInfo.password,
          firstLastname: this.MemberInfo.firstLastname,
          secondLastname: this.MemberInfo.secondLastname,
          name: this.MemberInfo.name,
          branch: this.MemberInfo.branch,
          numero_de_telefono: this.MemberInfo.numero_de_telefono,
          phone: this.MemberInfo.phone,
          memberData: this.MemberInfo.memberData,
          membershipPrice: this.MemberInfo.membershipPrice,
          assignedMembership: this.MemberInfo.assignedMembership,
          validationStatus: this.newValidationStatus,
          subgroup: this.MemberInfo.subgroup,
        };

        if (
          !this.MemberInfo.assignedMembership ||
          this.MemberInfo.assignedMembership == ""
        ) {
          // delete postUser.assignedMembership;
          delete postUser.assignedMembership;
        }

        const response = await this.axios({
          method: "post",
          url: "/members/form",
          data: postUser,
        });

        if (response.status === 200) {
          this.show({
            text: "¡Usuario creado exitosamente!",
            color: "success",
          });
          this.formDialog = false;
          this.$emit("update");
          await this.$refs.formuser.reset();
        } else {
          this.show({
            text: "¡Algo salio mal intentelo de nuevo mas tarde!",
            color: "error",
          });
        }
      } catch (error) {
        const response = error.response;
        let message =
          response && response.data && response.data.error
            ? response.data.error.message
            : "algo salio mal intentelo de nuevo mas tarde";

        if (
          message.includes("duplicate key") &&
          message.includes(this.MemberInfo.email)
        ) {
          this.show({
            text: "¡El email ya existe en otra cuenta!",
            color: "error",
          });
        } else {
          this.show({
            text: message,
            color: "error",
          });
        }
      }
    },

    //update member password
    async updateNewPassword() {
      let request = {};
      let userLogin = {};
      //validates if fields are empty
      if (
        this.passwordFields[0].content !== "" &&
        this.passwordFields[1].content !== "" &&
        this.passwordFields[2].content !== ""
      ) {
        //validates if the passwords meets the requirements
        if (
          this.$refs.textfield0[0].validations.length === 0 &&
          this.$refs.textfield1[0].validations.length === 0 &&
          this.$refs.textfield2[0].validations.length === 0
        ) {
          userLogin.email = this.user.email;
          userLogin.password = this.passwordFields[0].content;

          let loginResponse = await this.login(userLogin);

          if (loginResponse.status) {
            try {
              request.password = this.passwordFields[2].content;
              request.token = localStorage.getItem("USER_TOKEN");
              let response = await this.updatePassword(request);
              if (response.status) {
                this.show({
                  text: "Contraseña actualizada exitosamente",
                  color: "success",
                  time: 3000,
                });
              }
            } catch (error) {
              this.show({
                text: "Error actualizando las contraseñas, valida los campos e intentalo nuevamente.",
                color: "error",
                time: 3000,
              });
            }
          } else {
            this.show({
              text: "La contraseña ingresada es incorrecta",
              color: "error",
              time: 3000,
            });
          }
        } else {
          this.show({
            text: "Error actualizando las contraseñas, valida los campos e intentalo nuevamente.",
            color: "error",
            time: 3000,
          });
        }
      }
    },

    validateMembershipPrice() {
      this.MemberInfo.membershipPrice = parseInt(
        this.MemberInfo.membershipPrice
      );
      if (this.MemberInfo.membershipPrice < 0) {
        this.MemberInfo.membershipPrice = 0;
      }
    },

    // update membership price
    async updateMembershipPrice() {
      try {
        let data = {
          membershipPrice: this.MemberInfo.membershipPrice,
          assignedMembership: this.MemberInfo.assignedMembership,
          imefFiscalDataMembership: this.imefForm,
        }
        const response = await this.axios({
          method: 'POST',
          url: "/members/assignedMembership/" + this.member._id,
          data: data
        });

        if (response.status == 200) {
          this.show({
            text: "Nivel de membresía asignado correctamente",
            color: "success",
            time: 2000,
          });
        }
      } catch (error) {
        this.show({
          text: "Ha ocurrido un error al actualizar los datos de usuario",
          color: "error",
          time: 2000,
        });
      } finally {
        this.isMembershipPriceEditable = false;
      }
    },

    //updates a member
    async updateMember() {
      const valid = await this.$refs.formuser.validate();
      if (!valid) return;
      await this.formatDocs();
      try {
        let putUser = {
          email: this.MemberInfo.email,
          firstLastname: this.MemberInfo.firstLastname,
          secondLastname: this.MemberInfo.secondLastname,
          name: this.MemberInfo.name,
          memberData: this.MemberInfo.memberData,
          company: this.MemberInfo.company,
          phone: this.MemberInfo.phone,
          fiscalData: this.MemberInfo.fiscalData,
          stars: this.MemberInfo.stars,
          membershipPrice: this.MemberInfo.membershipPrice,
          assignedMembership: this.MemberInfo.assignedMembership,
          validationStatus: this.newValidationStatus,
          subgroup: this.MemberInfo.subgroup,
        };
        if (
          !this.MemberInfo.assignedMembership ||
          this.MemberInfo.assignedMembership == ""
        ) {
          // delete postUser.assignedMembership;
          delete putUser.assignedMembership;
        }
        const response = await this.axios({
          method: "put",
          url: "/members/form/" + this.member._id,
          data: putUser,
        });

        await this.handleImageUpdate();

        if (response.status === 200) {
          this.show({
            text: "¡Usuario actualizado exitosamente!",
            color: "success",
            time: 2000,
          });
          this.$emit("update");
          this.formDialog = false;
        } else {
          this.show({
            text: "¡Algo salio mal intentelo de nuevo mas tarde!",
            color: "error",
          });
        }
      } catch (error) {
        const response = error.response;
        let message =
          response && response.data && response.data.error
            ? response.data.error.message
            : "algo salio mal intentelo de nuevo mas tarde";

        if (
          message.includes("duplicate key") &&
          message.includes(this.MemberInfo.email)
        ) {
          this.show({
            text: "¡El email ya existe en otra cuenta!",
            color: "error",
          });
        } else {
          this.show({
            text: message,
            color: "error",
          });
        }
      } finally {
        if (
          this.user.role === "MEMBER" ||
          this.user.role === "MEMBERSHIP_USER"
        ) {
          await this.updateNewPassword();
        }
        if (this.user.role === "MEMBER") {
          await this.fetchMemberCredentials();
        } else if (this.user.role === "MEMBERSHIP_USER") {
          await this.fetchMembershipCredentials();
        }
      }
    },

    emitUpdate() {
      this.$emit("update");
      this.formDialog = false
    }
  },
  async created() {
    if (
      this.$route.fullPath.includes("/miembros") &&
      this.user.role !== "ORGANIZATION_USER"
    ) {
      this.organization = this.user._id;
    } else if (
      this.user.role === "ORGANIZATION_USER" &&
      this.$route.fullPath.includes("/miembros")
    ) {
      this.organization = this.user.organization;
    } else {
      this.organization = this.user.headOffice._id;
    }
    if (this.$route.fullPath.includes("tab=2")) {
      this.formDialog = true;
      await this.handleEdit();
      this.tab = "memberFiscalData";
    }
  },
  mounted() {
    if (this.user.role == "BRANCH") {
      this.MemberInfo.branch = this.user.company;
    }
    this.getAffiliationTypes();
  },

  watch: {
    $route: async function (val) { },
    "MemberInfo.assignedMembership"(prevIdSelected) {
      const index = [...this.affiliationTypes].findIndex(element => element._id == prevIdSelected)
      // this.imefForm =  this.affiliationTypes[index]?.imefFiscalData || {};
      // this.imefForm.eventCode = this.member?.imefAssignedMembershipServiceCode || '';
      this.imefForm = this.member?.imefFiscalDataMembership || {};
      this.imefForm.serviceCode = this.member?.imefFiscalDataMembership?.serviceCode || '';
    },
    "imefForm.group": function () {
      this.imefForm.zipCode = this.imefForm?.group?.zipCode || '';
      this.imefForm.serie = this.imefForm?.group?.segment === 42 ? 'MMTY' : 'NALM';
      this.imefForm.accountingSegment = this.imefForm?.group?.segment || '';
      this.imefForm.almacen = this.imefForm.accountingSegment
    },
  },
};
</script>
<style scoped>
.section-title {
  font-size: 18px;
  font-weight: 900;
  color: black;
}
</style>

<style>
.clickable-text {
  cursor: pointer;
}

.text-md {
  font-size: 18px;
}

.underline-active {
  text-decoration: underline;
  color: #000000;
}

.custom-text-field::v-deep .v-input__slot::before {
  border-bottom: none !important;
}

.recover-password-group  * {
  padding-bottom: 10px;
}
</style>
